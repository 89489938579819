import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import useToast from 'components/widgets/toast/useToast';
import { endpoints } from 'constants/api';
import useScrollHandler from 'hooks/intro-helper/useScrollHandler';
import TaooServerClient from 'http/axios';
import { All_PATHS_NAMESPACES } from 'routes/definedRoutes';
import checkSrc from 'services/utils/checkSrcImage';
import { isValidNumber } from 'services/validation';
import { setPoints } from 'store/points/action';
import {
    setCurrentStep, setLater, setNext, setNextStep,
} from 'store/step-process/action';
import { setOnBoarded } from 'store/user/action';

import Step0 from './1';
import Step1 from './2';
import Step2 from './3';
import SwitchButton from './component/switch-button';

function StepsContainer({
    onboarded = false,
    hasPin = false,
    setCurrentStep,
    onboardingStep,
    steps,
    setNext,
    setNextStep,
    setLater,
    setOnBoarded,
    user,
    lang = 'fr',
}) {
    const refName = useRef(null);
    const intl = useIntl();
    const { triggerToast } = useToast();
    const navigate = useNavigate();
    const [familyname, setFamilyname] = useState('');
    const [birthday, setBirthday] = useState('');
    const [choiceList, setChoiceList] = useState([]);
    const [isNextLoading, setNextLoader] = useState(false);
    const scrollHandler = useScrollHandler();
    const dispatch = useDispatch();

    const [error, setError] = useState(null);

    useEffect(() => {
        // scrollHandler && scrollHandler.enableScroll();

        if (onboarded || steps.later) {
            navigate(All_PATHS_NAMESPACES[lang].home.path);
        }
    }, [onboarded, steps.later]);
    useEffect(() => {
        TaooServerClient.get(endpoints.tags.getAll)
                .then((res) => {
                    const choices = res.data;
                    const modifiedChoices = choices.map((el) => ({
                        ...el,
                        icon: checkSrc(el.icon),
                    }));

                    setChoiceList(modifiedChoices);
                })
                .catch((err) => {
                    console.error(err);
                });

        if (onboardingStep >= 1) {
            setCurrentStep(onboardingStep - 1);
            setNext(true);
        } else {
            setCurrentStep(onboardingStep);
        }
    }, []);

    const [showFirstIntro, setShowFirstIntro] = useState(false);

    const handleSubmit = (e) => {
        const { currentStep, showMessage } = steps;

        e.preventDefault();

        if (currentStep === 0) {
            if (!showMessage) {
                if (refName.current?.value.length === 0) {
                    setError(
                        intl.formatMessage({
                            id: 'onboarding.step1.input.error',
                        }),
                    );

                    return;
                }

                setNextLoader(true);
                setError(null);

                TaooServerClient.post(endpoints.onBoarding.step1, {
                    firstName: refName.current?.value,
                })
                        .then((res) => {
                            const { totalPoints = 0, gainedPoints = 0, success = false } = res.data;

                            dispatch(setPoints(totalPoints));
                            setNext(false, {
                                totalPoints,
                                firstName: refName.current?.value,
                            });
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                        .finally(() => setNextLoader(false));
            } else {
                setError(null);

                setShowFirstIntro(true);
            }

            return;
        }

        if (currentStep === 1) {
            if (!showMessage) {
                const lastName = e.target.familyname.value;

                const year = parseInt(e.target.year[e.target.year.selectedIndex].textContent, 10);
                const month = parseInt(e.target.month[e.target.month.selectedIndex].textContent, 10);
                const day = parseInt(e.target.day[e.target.day.selectedIndex].textContent, 10);

                setFamilyname(lastName);

                if (!lastName || !isValidNumber(year) || !isValidNumber(month) || !isValidNumber(day)) {
                    setError(
                        intl.formatMessage({
                            id: 'onboarding.step2.input.error',
                        }),
                    );

                    // triggerToast(
                    //     intl.formatMessage({
                    //         id: 'onboarding.step2.input.error',
                    //     }),
                    //     'error',
                    // );

                    return;
                }

                const birthday = moment(`${ year }-${ month }-${ day }`, 'YYYY-MM-DD').format('yyyy-MM-DD HH:mm:ss');

                setBirthday(birthday);
                setNextLoader(true);
                setError(null);

                TaooServerClient.post(endpoints.onBoarding.step2, {
                    lastName,
                    birthday,
                })
                        .then((res) => {
                            const { totalPoints = 0, gainedPoints = 0, success = false } = res.data;

                            dispatch(setPoints(totalPoints));
                            setNext(false, { totalPoints, lastName });
                        })
                        .catch((err) => {
                            console.error(err);
                        })
                        .finally(() => setNextLoader(false));
            } else {
                setError(null);
                setNextStep();
            }

            return;
        }

        if (currentStep === 2) {
            if (!showMessage) {
                const inputElements = [...e.target.querySelectorAll('.checkboxTag')];

                const selectedTagsCount = inputElements.filter((el) => el && el.hasAttribute('checked'))?.length || 0;

                if (selectedTagsCount < 3) {
                    setError(
                        intl.formatMessage({
                            id: 'onboarding.step3.select.error',
                        }),
                    );

                    return;
                }

                setError(null);
                setNextLoader(true);

                const tags = [];

                inputElements.forEach((el) => {
                    if (el && el.hasAttribute('checked')) {
                        tags.push(parseInt(el.getAttribute('name')));
                    }
                });

                TaooServerClient.post(endpoints.onBoarding.step3, {
                    tags,
                })
                        .then((res) => {
                            const { totalPoints = 0, gainedPoints = 0, success = false } = res.data;

                            dispatch(setPoints(totalPoints));

                            if (!success) {
                                const error = new Error('onboarding.step3.select.error');

                                throw error;
                            }

                            setNext(false, { totalPoints });
                            setLater();
                            setOnBoarded();
                        })
                        .catch((err) => {
                            if (err.message === 'onboarding.step3.select.error') {
                                setError(intl.formatMessage({ id: err.message }));

                                return;
                            }

                            console.error(err);
                        })
                        .finally(() => setNextLoader(false));
            }
        }
    };

    return (
        <PageTransitionWrapper className="flex-grow-1 d-flex flex-column">
            <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.onboarding' })} />

            <form onSubmit={handleSubmit} className="flex-grow-1 pos-relative d-flex flex-column">
                <div className="steps-container flex-grow-1 pos-relative d-flex flex-column">
                    {!(steps.currentStep === 2 && steps.showMessage) && (
                        <>
                            <p style={{ marginTop: '1.2rem' }}>
                                <span>
                                    <FormattedMessage id="onboarding.step" /> {steps.currentStep + 1} /{' '}
                                </span>
                                <span className="num">3</span>
                            </p>

                            <div className="d-flex steps-progress">
                                {[0, 1, 2].map((el, index) => (
                                    <div
                                        key={index}
                                        className={`step-progress ${
                                            (steps.currentStep === el && steps.showMessage) || steps.currentStep > el
                                                ? 'active'
                                                : ''
                                        }`}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    <AnimatePresence exitBeforeEnter>
                        {steps.currentStep === 0 && (
                            <Step0
                                showMessage={steps.showMessage}
                                ref={refName}
                                remeberedStep={steps.remeberedStep}
                                error={error}
                                setNextStep={setNextStep}
                                showFirstIntro={showFirstIntro}
                            />
                        )}
                        {steps.currentStep === 1 && (
                            <Step1
                                showMessage={steps.showMessage}
                                ref={refName}
                                remeberedStep={steps.remeberedStep}
                                error={error}
                                familyname={familyname}
                                birthday={birthday}
                            />
                        )}
                        {steps.currentStep === 2 && <Step2 choiceList={choiceList} error={error} />}
                    </AnimatePresence>
                </div>
                {!(steps.currentStep === 2 && steps.showMessage) && (
                    <SwitchButton nextIsLoading={isNextLoading} laterIsLoading={false} />
                )}
            </form>
        </PageTransitionWrapper>
    );
}

const mapStateToProps = (state) => ({
    hasPin:         state.user.hasPin,
    onboarded:      state.user.onboarded,
    onboardingStep: state.user.onboardingStep,
    steps:          state.steps,
    user:           state.user,
});

export default connect(mapStateToProps, {
    setCurrentStep,
    setNext,
    setNextStep,
    setLater,
    setOnBoarded
})(StepsContainer);

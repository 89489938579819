import isEmpty from 'lodash/isEmpty';

import { domain } from 'constants/api';

const checkSrc = (src = '') => {
    if (isEmpty(src)) {
        return null;
    }

    return `${ domain }/uploads/${ src }`;
};

export default checkSrc;

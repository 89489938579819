import { motion } from 'framer-motion';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Loader from 'components/loaders/loader';
import { LoaderInButtonFillColor } from 'constants/defaults';
import { spring } from 'constants/index';
import { setLater } from 'store/step-process/action';

import styles from './index.module.scss';

function SwitchButton({ setLater, nextIsLoading, laterIsLoading }) {
    const [isOn, setIsOn] = useState(true);

    const handleLater = () => {
        setIsOn(false);

        setTimeout(() => {
            setLater();
        }, 250);
    };

    return (
        <div data-ison={isOn} className={`${ styles.switch } switch mt-3`}>
            <motion.div className={`${ styles.handle } handle`} layout transition={spring} />
            <div className={`${ styles.buttonWrapper } buttonWrapper`}>
                <p onClick={handleLater} className={`medium ${ !isOn ? 'is-on' : '' }`}>
                    {laterIsLoading ? (
                        <Loader fill={LoaderInButtonFillColor} />
                    ) : (
                        <FormattedMessage id="onboarding.step.later" />
                    )}
                </p>
                <button type="submit" disabled={nextIsLoading} className={`medium ${ isOn ? 'is-on' : '' }`}>
                    {nextIsLoading ? (
                        <Loader fill={LoaderInButtonFillColor} />
                    ) : (
                        <FormattedMessage id="onboarding.step.next" />
                    )}
                </button>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user:  state.user,
    steps: state.steps,
});

export default connect(mapStateToProps, { setLater })(SwitchButton);

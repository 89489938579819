import { switchMessageStep } from 'constants';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';

import PostOnboarding from 'components/post-onboarding';

import CheckBox from './check-box';

const Step = ({ error, choiceList }) => (
    <motion.div
        variants={switchMessageStep}
        initial="initial"
        animate="animate"
        exit="exit"
        className="d-flex flex-column pos-relative flex-grow-1"
    >
        <h3 className="mt-3">
            <FormattedMessage id="onboarding.step3.choose.interest" />
        </h3>
        <p>
            <FormattedMessage id="onboarding.step3.select_at_least_1" />
        </p>
        <div className="choice-list d-flex flex-wrap">
            {choiceList.map((el) => (
                <CheckBox choice={el} key={el.id} />
            ))}
        </div>
        {error && <p className="error"> {error} </p>}
    </motion.div>
);

export default Step;

import { useState } from 'react';

import Img from 'components/img/Img';

import styles from './index.module.scss';

function CheckBox({ choice }) {
    const [select, setSelect] = useState(false);

    return (
        <div
            onClick={() => {
                setSelect(!select);
            }}
            className={`d-flex align-items-center check-box ${ select ? 'selected' : '' } ${ styles['check-box'] }`}
        >
            {choice.icon && (
                <span className="img-wrapper mr-2">
                    <Img src={choice.icon} />
                </span>
            )}

            <div className="description">
                {choice.name}
                {/* <FormattedMessage id={choice.name.toLowerCase()} defaultMessage={choice.name} /> */}
            </div>
            <input className="checkboxTag" name={choice.id} type="checkbox" hidden defaultChecked={select} />
        </div>
    );
}

export default CheckBox;
